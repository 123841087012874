import React from "react";

export default function Preloader() {
  return (
    <div id="preloader">
      <svg
        id="loader"
        width="80"
        height="80"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="cup">
          <path
            id="handle"
            d="M33 14.6667H34.8333C36.7783 14.6667 38.6435 15.4393 40.0188 16.8146C41.394 18.1898 42.1667 20.0551 42.1667 22C42.1667 23.9449 41.394 25.8102 40.0188 27.1855C38.6435 28.5607 36.7783 29.3333 34.8333 29.3333H33"
            stroke="#056E9A"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="base"
            d="M3.66666 14.6667H33V31.1667C33 33.1116 32.2274 34.9769 30.8521 36.3521C29.4768 37.7274 27.6116 38.5 25.6667 38.5H11C9.05507 38.5 7.18981 37.7274 5.81454 36.3521C4.43927 34.9769 3.66666 33.1116 3.66666 31.1667V14.6667Z"
            stroke="#056E9A"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="in-steam"
            d="M18.3333 1.83333V7.33333"
            stroke="#056E9A"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="out-steam">
            <path
              id="left"
              d="M11 1.83333V7.33333"
              stroke="#056E9A"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="right"
              d="M25.6667 1.83333V7.33333"
              stroke="#056E9A"
              strokeWidth="2.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
      <p id="brewing">
        Brewing<span></span>
      </p>
    </div>
  );
}
