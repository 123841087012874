import "../../works.scss";
import "../single.scss";
import React from "react";
import { devProjectDetails } from "../../PortfolioItems/portfolioDetails";
import ProjectPageHeader from "../../PortfolioItems/ProjectPageHeader";

function KcmcWebsite() {
  const info = devProjectDetails[3];
  return (
    <div className="container">
      <ProjectPageHeader
        key={info.id}
        title={info.title}
        image={info.img}
        duration={info.year}
        role={info.role}
        tools={info.tools}
        team={info.team}
        languages={info.languages}
        link={info.link}
        github={info.github}
      />
      <div className="project-body">
        <h4>Kingstown / Chateaubelair Methodist Church</h4>
        <p>
          This website is in the process of being redesigned. However, this is
          the current design.
        </p>
        <h5>Screenshots</h5>
        <h6>Homescreen</h6>
        <img src={require("./1.png")} alt="" />
        <h6>Churches</h6>
        <img src={require("./2.png")} alt="" />
        <h6>Contact Us</h6>
        <img src={require("./3.png")} alt="" />
        <h5>Case Study</h5>
        <p>This case study is under construction.</p>
      </div>
    </div>
  );
}
export default KcmcWebsite;
