import "../../works.scss";
import "../single.scss";
import React from "react";
import { graphicPortfolio } from "../../PortfolioItems/portfolioDetails";
import ArtPageHeader from "../../PortfolioItems/ArtPageHeader";

function ThreeDArt() {
  const info = graphicPortfolio[0];
  return (
    <div className="container art">
      <ArtPageHeader
        key={info.id}
        title={info.title}
        image={info.img}
        role={info.role}
      />
      <img
        id="animation"
        src={require("../../PortfolioItems/images/3D Art/1.webp")}
        alt=""
      />
      <div className="grid-container">
        <img src={require("../../PortfolioItems/images/3D Art/2.jpg")} alt="" />
        <img src={require("../../PortfolioItems/images/3D Art/3.jpg")} alt="" />
      </div>
    </div>
  );
}
export default ThreeDArt;
