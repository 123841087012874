import "../../works.scss";
import "../single.scss";
import React from "react";
import { graphicPortfolio } from "../../PortfolioItems/portfolioDetails";
import ArtPageHeader from "../../PortfolioItems/ArtPageHeader";

function VectorArt() {
  const info = graphicPortfolio[2];
  return (
    <div className="container art">
      <ArtPageHeader
        key={info.id}
        title={info.title}
        image={info.img}
        role={info.role}
      />
      <div className="grid-container">
        <img
          src={require("../../PortfolioItems/images/Vector Art/1.jpg")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Vector Art/2.jpg")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Vector Art/3.jpg")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Vector Art/4.jpg")}
          alt=""
        />
      </div>
    </div>
  );
}
export default VectorArt;
