import Intro from "./components/intro/Intro";
import About from "./components/about/About";
import Works from "./components/works/Works";
import Contact from "./components/contact/Contact";
import Preloader from "./components/preloader/Preloader";
import { useState, useEffect } from "react";
import "./app.scss";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <Preloader />
      ) : (
        <div className="main">
          <div className="sections">
            <Intro />
            {/* <PageHeader/> */}
            <Works />
            <About />
          </div>
          <Contact />
        </div>
      )}
    </div>
  );
}

export default App;
