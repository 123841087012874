import "./intro.scss";
import Nav from "../nav/Nav";

export default function Intro() {
  return (
    <div className="intro">
      {/* <video className="hero-video" muted loop autoPlay>
                <source src={hero} type="video/mp4" />
            </video> */}
      <div className="container">
        <Nav />
        <div className="inner">
          <div className="intro-text">
            {/* <p>Hi, there! My name is</p> */}
            <h1>
              Hi, there! <br />
              My name is <b>Shanis Cato</b>.
            </h1>
            <p>
              I’m a Software Engineering student at the University of the West
              Indies. I love UX/UI Design and Web Development. Currently, I am
              focusing on building more accessible web pages in the Caribbean.
            </p>

            <a href="#contact">
              <button className="primary">Contact Me</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
