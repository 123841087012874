import React from "react";
import { Link } from "react-router-dom";
const _ = require("lodash");

export default function PortfolioThumb(props) {
  return (
    <Link to={`/project/${_.kebabCase(props.title)}`} key={props.id}>
      <div className="item">
        <img src={props.image} alt="" />
        <h5>{props.title}</h5>
        <small>{props.roles}</small>
        {/* <div className="info">
                <h4>Project Name</h4>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure doloribus, minima itaque voluptatum quos repellat!</p>
                <ul className="project-tags">
                    <li><button className="chip-secondary">Chip 1</button></li>
                    <li><button className="chip-secondary">Chip 2</button></li>
                    <li><button className="chip-secondary">Chip 3</button></li>
                </ul>
            </div> */}
      </div>
    </Link>
  );
}
