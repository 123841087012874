import "../../works.scss";
import "../single.scss";
import React from "react";
import { devProjectDetails } from "../../PortfolioItems/portfolioDetails";
import ProjectPageHeader from "../../PortfolioItems/ProjectPageHeader";

function UwiberWeb() {
  const info = devProjectDetails[1];
  return (
    <div className="container">
      <ProjectPageHeader
        key={info.id}
        title={info.title}
        image={info.img}
        duration={info.year}
        role={info.role}
        tools={info.tools}
        team={info.team}
        languages={info.languages}
        github={info.github}
      />
      <div className="project-body">
        <h4>UWIber</h4>
        <p>
          UWIber is a fictional company that provides taxi service to the
          students of the University of the West Indies, Mona. Students have a
          variety of options to be transported to and from the campus, in and
          around the area of Kingston and St. Andrew in Jamaica.
        </p>
        <h4>UWIber Admin</h4>
        <p>
          UWIber Admin is a web app that provides CRUD functionality to the
          administrators of UWIber. It allows the administrator user to see an
          overall dashboard of the statistics of the app, see the list of
          drivers, clients and rides, and be able to create and update a client
          or driver’s details right from the app.This project was done as a part
          of a group assignment in a university course called 'Web & Mobile App
          Development' to create a web application that connects to an
          accompanying Android and iOS app.
        </p>
        <img src={require("./Dashboard Screen.png")} alt="" />
        <h5>Design</h5>
        <h6>Prototyping</h6>
        <p>
          As this project focuses more on the web development aspects, a simple
          high fidelity prototype was made to show our supervisor. In
          retrospect, this could've simply been done as a wireframe. However, it
          was my first prototype done in Figma.
        </p>
        <img src={require("./original prototype.jpg")} alt="" />
        <h6>Original Template without Modifications</h6>
        <p>
          Another UI designer and I found this Bootstrap template and we
          modified it to suit or original prototype.
        </p>
        <p>
          Source:{" "}
          <a
            href="https://startbootstrap.com/theme/sb-admin-2"
            rel="noreferrer"
          >
            Bootstrap SB Admin 2
          </a>
        </p>
        <img src={require("./sb-admin2.jpg")} alt="" />
        <p>This is our design modification in mobile size.</p>
        <img src={require("./mobile.png")} alt="" />
        <h5>Project Features</h5>
        <ul>
          <li>Login</li>
          <li>Dashboard Overview</li>
          <li>CRUD</li>
          <li>REST API</li>
        </ul>
        <h5>Operating Environment</h5>
        <ul>
          <li>System: Client/Server</li>
          <li>Operating System: Agnostic</li>
          <li>
            Database: SQLite, to be updated to PostgreSQL and deployed on AWS
          </li>
        </ul>
        <h5>Login and Authorization</h5>
        <p>
          The Login and Register functionality creates or log in a user. The
          'create user' form is created with Django's built-in UserCreationForm.
          If a user is authenticated and tries to go to the login or register
          URL, they will be redirected to the home dashboard page. A user can
          also log out or see their profile information.
        </p>
        <img src={require("./login.png")} alt="" />
        <h5>CRUD functionality - Drivers, Clients, and Rides </h5>
        <p>This gives the administrative user the ability to: </p>
        <ul>
          <li>Create - a new driver, or client</li>
          <li>Retrieve - drivers, clients, and rides</li>
          <li>Update - drivers and clients</li>
          <li>Delete - this functionality is not available yet</li>
        </ul>
        <img src={require("./driver info.png")} alt="" />
        <p>
          This is an administrative dashboard. It displays data dynamically from
          the database in an overview. This includes the total number of users,
          the requests for rides made this week, and the average gross per week.
          Additionally, it gives a list of the most recently active users and
          recent rides.
        </p>
        <p>
          The Javascript charts also display the most popular locations, the
          requests per month.
        </p>
        <img src={require("./drivers crud.png")} alt="" />
      </div>
    </div>
  );
}
export default UwiberWeb;
