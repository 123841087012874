const tagList = [
  {
    id: "all",
    title: "All",
  },
  {
    id: "web",
    title: "Web Dev",
  },
  {
    id: "mobile",
    title: "Mobile Dev",
  },
  {
    id: "ux",
    title: "UX Design",
  },
  {
    id: "ui",
    title: "UI Design",
  },
  {
    id: "graphic",
    title: "Graphic Design",
  },
  {
    id: "illustration",
    title: "Illustration",
  },
  {
    id: "research",
    title: "Research",
  },
];
export default tagList;
