import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import {
  devProjectDetails,
  graphicPortfolio,
} from "./components/works/PortfolioItems/portfolioDetails";
import UwiberWeb from "./components/works/pages/UWIberWeb/UwiberWeb";
import UwiberMobile from "./components/works/pages/UWIberMobile/UwiberMobile";
import Wazzit from "./components/works/pages/Wazzit/Wazzit";
import KcmcWebsite from "./components/works/pages/KCMCWebsite/KCMCWebsite";
import ThreeDArt from "./components/works/pages/Art/ThreeDArt";
import GraphicDesign from "./components/works/pages/Art/GraphicDesign";
import VectorArt from "./components/works/pages/Art/VectorArt";

const _ = require("lodash");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />

        {/* <Route path="/project/wazzit" element={<UwiberWeb />} /> */}
        <Route
          path={`/project/${_.kebabCase(devProjectDetails[0].title)}`}
          element={<Wazzit />}
        />
        <Route
          path={`/project/${_.kebabCase(devProjectDetails[1].title)}`}
          element={<UwiberWeb />}
        />
        <Route
          path={`/project/${_.kebabCase(devProjectDetails[2].title)}`}
          element={<UwiberMobile />}
        />
        <Route
          path={`/project/${_.kebabCase(devProjectDetails[3].title)}`}
          element={<KcmcWebsite />}
        />
        <Route
          path={`/project/${_.kebabCase(graphicPortfolio[0].title)}`}
          element={<ThreeDArt />}
        />
        <Route
          path={`/project/${_.kebabCase(graphicPortfolio[1].title)}`}
          element={<GraphicDesign />}
        />
        <Route
          path={`/project/${_.kebabCase(graphicPortfolio[2].title)}`}
          element={<VectorArt />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
