import tagList from "./tagList.js";

export const devProjectDetails = [
  {
    id: 1,
    title: "Wazzit?",
    img: require("./images/Wazzit.jpg"),
    github: "https://github.com/shaniscato/UWIberAdmin",
    link: "Not available",
    team: "Urban IQ",
    role: [tagList[4].title, tagList[2].title],
    languages: ["Dart"],
    tools: ["Figma", "Flutter"],
    year: "2022",
    summary:
      "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app.",
  },
  {
    id: 2,
    title: "UWIber Web App",
    img: require("./images/UWIber Web.png"),
    github: "https://github.com/shaniscato/UWIberAdmin",
    link: "Not available",
    team: "Urban IQ",
    role: [tagList[1].title, tagList[4].title],
    languages: ["Python"],
    tools: ["Figma", "Django", "Django Rest API", "Bootstrap"],
    year: "2020-2021",
    summary:
      "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app.",
  },
  {
    id: 3,
    title: "UWIber Mobile App",
    img: require("./images/UWIber Mobile.jpg"),
    github: "https://github.com/UrbanIQTeam/UWIberAndroid",
    link: "Not available",
    team: "Urban IQ",
    role: [tagList[2].titletag, tagList[4].title],
    languages: ["Kotlin", "Swift"],
    tools: ["Figma", "Android Studio", "XCode"],
    year: "2020-2021",
    summary:
      "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app.",
  },
  {
    id: 4,
    title: "KCMC Website",
    img: require("./images/kcmc.jpg"),
    github: "https://github.com/shaniscato/churchtheme",
    link: "https://kingchatmethodist.com",
    team: "Self",
    role: [tagList[1].title],
    languages: ["PHP"],
    tools: ["Bootstrap", "Wordpress CMS"],
    year: "2016",
    summary:
      "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app.",
  },
  // {
  //     id: 4,
  //     title: "Parsley Patch",
  //     img: require("./images/Parsley Patch.jpg"),
  //     role: [tagList[6].title],
  //     language: [""],
  //     tools: [""],
  //     year: "2020",
  //     summary: "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app."
  // }
];

export const graphicPortfolio = [
  {
    id: 5,
    title: "3D Art",
    img: require("./images/Graphic Art/hero polygon.jpg"),
    role: [tagList[5].title],
  },
  {
    id: 6,
    title: "Graphic Design",
    img: require("./images/Graphic Art/1.png"),
    role: [tagList[5].title],
  },
  {
    id: 7,
    title: "Vector Art",
    img: require("./images/Vector Art/2.jpg"),
    role: [tagList[5].title, tagList[6].title],
  },
];

//export const webPortfolio = [
//   {
//     id: 1,
//     title: "UWIber Web App",
//     img: "./images/UWIber Web.png",
//     role: [tagList[1].title, tagList[4].title],
//     language: ["Python"],
//     tools: ["Figma", "Django", "Django Rest API", "Bootstrap"],
//     year: "2020-2021",
//     summary:
//       "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app.",
//   },
//   {
//     id: 2,
//     title: "KCMC Website",
//     img: "./images/kcmc.jpg",
//     role: [tagList[7].title],
//     language: ["PHP"],
//     tools: ["Bootstrap", "Wordpress CMS"],
//     year: "2016",
//     summary:
//       "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app.",
//   },
// ];

// export const mobilePortfolio = [
//   {
//     id: 1,
//     title: "UWIber Mobile App",
//     img: "./images/UWIber Mobile.jpg",
//     role: [tagList[2].titletag, tagList[4].title],
//     language: ["Kotlin", "Swift"],
//     tools: ["Figma", "Android Studio", "XCode"],
//     year: "2020-2021",
//     summary:
//       "UWIber is a company that provides taxi service to the students of the University of the West Indies, Mona. This project consists of an Android and iOS for clients, as well as an accompanying administrative web app.",
//   },
// ];
