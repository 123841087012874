import React from "react";
import Github from "../../contact/icons/Github";
import Nav from "../../nav/Nav";
import "./pageheader.scss";

const _ = require("lodash");

export default function ProjectPageHeader(props) {
  const roleItems = props.role;
  const languages = props.languages;
  const tools = props.tools;

  return (
    <div className="page-header" id={_.kebabCase(props.title)}>
      <Nav />
      <div className="grid-container">
        <img className="portfolio-hero" src={props.image} alt="" />
        <div className="info">
          <h3>{props.title}</h3>
          <p>
            <b>Github</b>
          </p>
          <a href={props.github} target="_blank" rel="noreferrer">
            <span>
              <Github />
            </span>
          </a>
          <p>
            <b>Website</b>
          </p>
          <a href={props.link} target="_blank" rel="noreferrer">
            <span>{props.link}</span>
          </a>
          <p>
            <b>My Role</b>
          </p>
          <ul>
            {roleItems.map((item, id) => (
              <li key={id}>{item}</li>
            ))}
          </ul>
          <p>
            <b>Team</b>
          </p>
          <p>{props.team}</p>
          <p>
            <b>Languages</b>
          </p>
          <ul>
            {languages.map((item, id) => (
              <li key={id}>{item}</li>
            ))}
          </ul>
          <p>
            <b>Tools and Technology</b>
          </p>
          <ul>
            {tools.map((item, id) => (
              <li key={id}>{item}</li>
            ))}
          </ul>
          <p>
            <b>Duration</b>
          </p>
          <p>{props.duration}</p>
        </div>
      </div>
    </div>
  );
}
