import "./nav.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

function Nav() {
  const [isActive, toggleActive] = useState(false);
  const [isExit, toggleExit] = useState(false);

  function handleClick() {
    toggleActive(!isActive);
    toggleExit(!isExit);
  }
  return (
    <header>
      <nav>
        <Link to="/">
          <img
            className="logo"
            src={require("./logo.png")}
            alt="Logo, link to home"
          />
        </Link>
        <ul className={isActive ? "active" : null}>
          <li>
            {/* <Link to="/">About</Link> */}
            <a href="/#works">Works</a>
          </li>
          <li>
            <a href="/#about">About</a>
            {/* <Link to="/#works">Works</Link> */}
          </li>
          <li>
            <a href="/#contact">Contact</a>
            {/* <Link to="/#contact">Contact</Link> */}
          </li>
        </ul>
        <div
          className={isExit ? "burger toggle" : "burger"}
          onClick={handleClick}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </header>
  );
}

export default Nav;

// export default function Nav() {
//     const [isActive, toggleActive] = useState(false);

//     function handleClick(){
//         toggleActive(!isActive)
//     }

//     return (
//         <header>
//             <nav>
//                 <img className="logo" src={require('./logo.png')} alt="Logo, link to home"/>
//                 <ul>
//                     <li><a href="#">About</a></li>
//                     <li><a href="#">Works</a></li>
//                     <li><a href="#">Contact</a></li>
//                 </ul>
//                 <div className="burger" className={isActive ? 'active': null} onClick={handleClick}>
//                     <div></div>
//                     <div></div>
//                     <div></div>
//                 </div>
//             </nav>
//         </header>
//     )
// }
