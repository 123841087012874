import "../../works.scss";
import "../single.scss";
import React from "react";
import { graphicPortfolio } from "../../PortfolioItems/portfolioDetails";
import ArtPageHeader from "../../PortfolioItems/ArtPageHeader";

function GraphicDesign() {
  const info = graphicPortfolio[1];
  return (
    <div className="container art">
      <ArtPageHeader
        key={info.id}
        title={info.title}
        image={info.img}
        role={info.role}
      />
      <div className="grid-container">
        <img
          src={require("../../PortfolioItems/images/Graphic Art/1.png")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Graphic Art/2.png")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Graphic Art/6.png")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Graphic Art/5.png")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Graphic Art/3.png")}
          alt=""
        />
        <img
          src={require("../../PortfolioItems/images/Graphic Art/4.png")}
          alt=""
        />
      </div>
    </div>
  );
}
export default GraphicDesign;
