import "./works.scss";
import React, { useState } from "react";
import PortfolioThumb from "./PortfolioItems/PortfolioThumb";
import mug from "./bg/cup.jpg";
import {
  devProjectDetails,
  graphicPortfolio,
} from "./PortfolioItems/portfolioDetails";

function createWork(portfolioItem) {
  return (
    <PortfolioThumb
      key={portfolioItem.id}
      image={portfolioItem.img}
      roles={portfolioItem.roles}
      title={portfolioItem.title}
    />
  );
}

function createArt(graphicsItem) {
  return (
    <PortfolioThumb
      key={graphicsItem.id}
      image={graphicsItem.img}
      roles={graphicsItem.roles}
      title={graphicsItem.title}
    />
  );
}

function Works() {
  // const [isSelected, setSelected] = useState("all");
  const [visible, setVisible] = useState(0);

  // useEffect(() => {
  //     fetch
  // }, []);
  const showMoreItems = () => {
    setVisible((prevValue) =>
      prevValue < graphicPortfolio.length ? prevValue + 4 : prevValue
    );
  };

  return (
    <div id="works">
      <h2>My Work</h2>
      <div className="portfolio">
        {/* <ul className='portfolio-tags'>
                    {tagList.map(item =>(
                        <PortfolioList title={item.title} key={item.id} active={isSelected === item.id} id={item.id} setSelected={setSelected}/>
                    ))}
                </ul> */}
        <div className="grid-container">
          {devProjectDetails.slice(0, 4).map(createWork)}
          {graphicPortfolio.slice(0, visible).map(createArt)}
        </div>
        {/* <button onClick={showMoreItems} className="primary" style={isSelected === 'all' ? {visibility:'visible'} : {visibility:'hidden'}}>See more</button> */}
        <button
          onClick={showMoreItems}
          className="primary"
          style={
            visible >= graphicPortfolio.length
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        >
          See more
        </button>

        <img src={mug} alt="" id="mug" />
      </div>
    </div>
  );
}

export default Works;
