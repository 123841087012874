import "../../works.scss";
import "../single.scss";
import React from "react";
import { devProjectDetails } from "../../PortfolioItems/portfolioDetails";
import ProjectPageHeader from "../../PortfolioItems/ProjectPageHeader";

function Wazzit() {
  const info = devProjectDetails[0];
  return (
    <div className="container">
      <ProjectPageHeader
        key={info.id}
        title={info.title}
        image={info.img}
        duration={info.year}
        role={info.role}
        tools={info.tools}
        team={info.team}
        languages={info.languages}
      />
      <div className="project-body">
        <h3>Wazzit</h3>
        <h5>Overview</h5>
        <p>
          Wazzit seekes to provide an immersive language learning experience
          using comprehensible input, through a scavenger hunt style game which
          allows the user to select their target languages and levels. Users are
          prompted with a sentence which describes the object they must find,
          and they must explore the world around them to detect the described
          item and learn new vocabulary. Each prompt is saved as a flashcard for
          later review.
        </p>
        <h4>The Process</h4>
        <h5>Problem and Solution</h5>
        <p>
          There were many discussions among the Urban IQ team as to the features
          that this language learning app should entail. We settled on 4 main
          features:
        </p>
        <ul>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-camera"
            >
              <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
              <circle cx="12" cy="13" r="4"></circle>
            </svg>
            Object Recognition: Using the device's camera to create immersion.
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-smile"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
              <line x1="9" y1="9" x2="9.01" y2="9"></line>
              <line x1="15" y1="9" x2="15.01" y2="9"></line>
            </svg>
            Scavenger Hunt: Gamification to keep the user's interest, account
            for user competencies, and preserve their interest and motivation.
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-book-open"
            >
              <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
              <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
            </svg>
            Flashcard Review: Semantic Flashcards using the user's voice and
            real-world pictures.
          </li>
        </ul>
        <p>
          <b>My role: </b>Consisted of refining the problem and solution
          statements, and ensuring that our solution was unique and solved a
          real on-going problem.
        </p>
        <h5>Competitor Analysis</h5>
        <p>
          Other team members worked together to create the competitor analysis.
        </p>
        <img src={require("./competitor analysis.jpg")} alt="" />
        <h5>Prioritisation of Issues</h5>
        <p>
          My task was to gather the user stories in the template of "As a..." "I
          want to..." "so that I can". This involved 4 users. After doing so,
          the tasks were prioritized for developmenent. However, this is an
          on-going process and more users are intended to be interviewed.
        </p>
        <img src={require("./user stories.jpg")} alt="" />
        <h5>Wireframing</h5>
        <p>
          3 of the group members, including myself drew sketches of the concept
          in our minds as wireframes.
        </p>
        <img src={require("./draft 2.jpg")} alt="" />
        <img src={require("./draft 1.png")} alt="" />
        <h6>Final Wireframe</h6>
        <p>
          It was then my task to discuss the features we would add to the final
          wireframe, and to put them all together.
        </p>
        <img src={require("./wireframe.jpg")} alt="" />
        <p>
          The wireframe was improved over a series of iterations, with the main
          screens being Login/Registration, Onboarding, Scavenger Hunt (which
          consists of choosing a category as well as the actual gameplay),
          Review Flashcards, and Account Settings.
        </p>
        <h5>From Low-Fidelity to High</h5>
        <p>
          The following are examples of screens I designed after a process of
          looking at design inspiration on Pinterest, Dribbble, and Behance to
          convert the wireframes into high-fidelity prototypes.{" "}
        </p>

        <img src={require("./Example Screens of Main Flow.jpg")} alt="" />
        <img src={require("./Swipable Onboarding Pages.jpg")} alt="" />
        <p>
          I chose to layout components on an 8px grid. This makes element
          scaling on multiple devices more consistent.
        </p>
        <img src={require("./Figma 2.jpg")} alt="" />
        <p>
          Components, text styles and colour styles were also created for reuse.
        </p>
        <img src={require("./Figma.jpg")} alt="" />
        <p>
          Since this is an ongoing project, more details will be added soon.
        </p>
      </div>
    </div>
  );
}
export default Wazzit;
