import React from "react";
import Nav from "../../nav/Nav";
import "./pageheader.scss";

const _ = require("lodash");

export default function AgePageHeader(props) {
  const roleItems = props.role;

  return (
    <div className="page-header" id={_.kebabCase(props.title)}>
      <Nav />
      <div className="grid-container">
        <img className="portfolio-hero" src={props.image} alt="" />
        <div className="info">
          <h3>{props.title}</h3>
          <p>
            <b>My Role</b>
          </p>
          <ul>
            {roleItems.map((item, id) => (
              <li key={id}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
