import "../../works.scss";
import "../single.scss";
import React from "react";
import { devProjectDetails } from "../../PortfolioItems/portfolioDetails";
import ProjectPageHeader from "../../PortfolioItems/ProjectPageHeader";

function UwiberMobile() {
  const info = devProjectDetails[2];
  return (
    <div className="container">
      <ProjectPageHeader
        key={info.id}
        title={info.title}
        image={info.img}
        duration={info.year}
        role={info.role}
        tools={info.tools}
        team={info.team}
        languages={info.languages}
      />
      <div className="project-body art">
        <h4>UWIber</h4>
        <p>This page is under construction.</p>
        <p>
          UWIber is a fictional company that provides taxi service to the
          students of the University of the West Indies, Mona. Students have a
          variety of options to be transported to and from the campus, in and
          around the area of Kingston and St. Andrew in Jamaica.
        </p>
        <h5>Project Features</h5>
        <ul>
          <li>CRUD Requests</li>
          <li>Apple Maps functionality</li>
          <li>Google Maps functionality</li>
          <li>Notifications</li>
        </ul>
        <h4>UWIber Mobile</h4>
        <p>
          The mobile version of UWIber is a mobile app that takes advantage of
          REST technologies to allow the user to use web functionalities. It
          allows a user to login and request a ride to certain locations in the
          environs of the University of the West Indies Mona Campus.
        </p>
        <h5>iOS</h5>
        <p>
          UWIber iOS was built using the Swift programming languages and various
          supporting libraries.
        </p>
        <div className="grid-container">
          <img src={require("./12.png")} alt="" />
          <img src={require("./11.png")} alt="" />
          <img src={require("./10.png")} alt="" />
          <img src={require("./9.png")} alt="" />
          <img src={require("./8.png")} alt="" />
        </div>

        <h5>Android</h5>
        <p>
          UWIber Android was built using the Kotlin programming language and
          various supporting libraries.
        </p>
        <div className="grid-container">
          <img src={require("./7.png")} alt="" />
          <img src={require("./6.png")} alt="" />
          <img src={require("./3.png")} alt="" />
          <img src={require("./5.png")} alt="" />
          <img src={require("./4.png")} alt="" />
        </div>
        {/* <img src={require("./Dashboard Screen.png")} alt="" /> */}

        {/* <img src={require("./mobile.png")} alt="" /> */}

        {/* <h5>Operating Environment</h5>
        <ul></ul> */}
      </div>
    </div>
  );
}
export default UwiberMobile;
