import "./about.scss";
import portrait from "./shanis.jpg";
import resume from "./bg/resume 3.jpg";

export default function About() {
  return (
    <div id="about">
      <div className="inner">
        <img src={portrait} alt="Shanis Cato" className="portrait" />
        <div className="text">
          <h2>About Me</h2>
          <p>
            Hi! My name is Shanis Cato. I currently live in St. Vincent and the
            Grenadines, and have lived in 3 other Caribbean countries.
          </p>

          <p>
            Creativity and technology are my passions so I love being able to
            combine the two in my web development and UI designs. I have also
            observed in the Caribbean that accessibility in design is often
            neglected, and this is something I want to change.{" "}
          </p>

          <p>
            Outside of web development, I also do graphic design, as well as
            play the violin and the piano.
          </p>
          <p>
            To learn more about my skills, experience and education, you can
            download my CV.
          </p>
          <a
            href={require("./Shanis Cato - Online Resume March 2022.pdf")}
            target="_blank"
            rel="noreferrer"
          >
            <button className="primary">Download CV</button>
          </a>
          {/* <a href={require("./Shanis Cato - Resume.pdf")} target="_blank">
            <button className="primary">Download CV</button>
          </a> */}
        </div>
        <img src={resume} alt="" id="resume" />
      </div>
    </div>
  );
}
