import "./contact.scss";
import contact from "./bg/1.jpg";
import contactTablet from "./bg/1-tablet.jpg";
import contactMobile from "./bg/1-mobile.jpg";
import LinkedIn from "./icons/LinkedIn";
import Github from "./icons/Github";
import Instagram from "./icons/Instagram";
import Dribbble from "./icons/Dribbble";

export default function Contact() {
  return (
    <div id="contact">
      <div className="container">
        <h2>Contact Me</h2>
        <form>
          <label>Name: </label>
          <input type="text" />
          <label>Email: </label>
          <input type="email" />
          <label>Write me a message:</label>
          <textarea rows="4" cols="50" name="comment" form="usrform"></textarea>
          <button className="primary">Submit</button>
        </form>

        <div className="links">
          <small style={{ marginTop: "24px", position: "absolute" }}>
            Portfolio designed and developed by Shanis Cato.
          </small>
          <a
            rel="noreferrer"
            href="https://www.linkedin.com/in/shanis-cato/"
            target="_blank"
          >
            <LinkedIn />
          </a>
          <a
            rel="noreferrer"
            href="https://www.instagram.com/shan.kates/"
            target="_blank"
          >
            <Instagram />
          </a>
          <a
            rel="noreferrer"
            href="https://dribbble.com/shanis_cato"
            target="_blank"
          >
            <Dribbble />
          </a>
          <a
            rel="noreferrer"
            href="https://github.com/shaniscato"
            target="_blank"
          >
            <Github />
          </a>
        </div>
      </div>

      <picture>
        <source
          srcSet={contact}
          media="(min-width: 1024px) and (orientation: landscape)"
        />
        <source srcSet={contactTablet} media="(min-width: 768px)" />
        <img srcSet={contactMobile} className="bg" alt="Hero" />
      </picture>
    </div>
  );
}
